import moment from 'moment'


export function setCartExpiredDate(minutes) {
  if(typeof window === 'undefined') {
    return false
  }
  const cartDate = moment()
  window.localStorage.setItem('isCart', cartDate.add(minutes, 'minutes'))
}

export function deleteCartExpiredDate() {
  if(typeof window === 'undefined') {
    return false
  }
  window.localStorage.removeItem('isCart')
}

export function checkCartExpiredDate() {
  if(typeof window === 'undefined') {
    return false
  }
  const cartDate = window.localStorage.getItem('isCart')
  if(!cartDate) {
    return false
  }
  return moment(cartDate).diff(moment(), 'minutes') > 0
}
